exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-bienvenida-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/bienvenida/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-bienvenida-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-bienes-y-servicios-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/bienes-y-servicios/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-bienes-y-servicios-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-combustibles-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/combustibles/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-combustibles-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-comparador-de-emisiones-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/comparador-de-emisiones/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-comparador-de-emisiones-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-electricidad-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/electricidad/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-electricidad-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-gases-refrigerantes-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/gases-refrigerantes/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-gases-refrigerantes-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-huella-de-carbono-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/huella-de-carbono/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-huella-de-carbono-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-residuos-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/residuos/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-residuos-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-transporte-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/calculadoras/transporte/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-calculadoras-transporte-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-alcance-2-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/alcance-2/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-alcance-2-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-cadena-de-valor-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/cadena-de-valor/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-cadena-de-valor-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-captura-de-carbono-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/captura-de-carbono/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-captura-de-carbono-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-eficiencia-energetica-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/eficiencia-energética/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-eficiencia-energetica-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-energia-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/energia/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-energia-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-energia-solar-fotovoltaica-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/energia-solar-fotovoltaica/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-energia-solar-fotovoltaica-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-energias-renovables-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/energias-renovables/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-energias-renovables-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-estrategia-climatica-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/estrategia-climatica/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-estrategia-climatica-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-gases-fluorados-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/gases-fluorados/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-gases-fluorados-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-jerarquia-de-descarbonizacion-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/jerarquia-de-descarbonizacion/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-jerarquia-de-descarbonizacion-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-net-zero-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/net-zero/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-net-zero-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-objetivos-de-reduccion-de-emisiones-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/objetivos-de-reduccion-de-emisiones/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-objetivos-de-reduccion-de-emisiones-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-transporte-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/transporte/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-transporte-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-vehiculos-electricos-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/descarbonizacion/vehiculos-electricos/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-descarbonizacion-vehiculos-electricos-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-guias-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/guias/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-guias-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-actividades-de-clientes-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/actividades-de-clientes/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-actividades-de-clientes-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-alcances-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/alcances/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-alcances-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-bienes-y-servicios-ejemplo-1-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/bienes-y-servicios/ejemplo1.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-bienes-y-servicios-ejemplo-1-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-bienes-y-servicios-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/bienes-y-servicios/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-bienes-y-servicios-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-emisiones-de-alcance-1-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/emisiones-de-alcance-1/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-emisiones-de-alcance-1-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-emisiones-de-alcance-2-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/emisiones-de-alcance-2/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-emisiones-de-alcance-2-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-emisiones-de-alcance-3-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/emisiones-de-alcance-3/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-emisiones-de-alcance-3-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-ghg-protocol-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/ghg-protocol/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-ghg-protocol-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-limites-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/limites/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-limites-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-preguntas-frecuentes-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/preguntas-frecuentes/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-preguntas-frecuentes-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-principios-de-calculo-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/principios-de-calculo/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-principios-de-calculo-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-proceso-de-calculo-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/proceso-de-calculo/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-proceso-de-calculo-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-residuos-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/residuos/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-residuos-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-transporte-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/huella-de-carbono/transporte/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-huella-de-carbono-transporte-index-mdx" */),
  "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-servicios-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__section}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/servicios/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-section-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-content-servicios-index-mdx" */)
}

